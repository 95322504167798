<template>
  <a-modal
      title="新增用户"
      :visible="disabled"
      :confirm-loading="confirmLoading"
      :maskClosable="false"
      okText="提交"
      cancelText="取消"
      @ok="handleOk"
      @cancel="handleCancel"
  >
    <div class="oneDiv">
      <p>用户名：</p>
      <a-input class="input" placeholder="请输入用户名" v-model="formData.username"/>
    </div>
    <div class="oneDiv">
      <p>登录密码：</p>
      <a-input class="input" placeholder="请输入登录密码" v-model="formData.password"/>
    </div>
    <!-- <div class="oneDiv">
      <p>用户姓名：</p>
      <a-input class="input" placeholder="请输入用户姓名" v-model="formData.nickName"/>
    </div> -->
    <div class="oneDiv">
      <p>角色：</p>
      <a-select style="width: 200px;" v-model="formData.roleId" mode="default" placeholder="请选择角色">
        <a-select-option v-for="(a, index) in role_list" :key="index" :value="a.roleId">
          {{ a.name }}
        </a-select-option>
      </a-select>
    </div>
    <div class="oneDiv">
      <p>工作组：</p>
      <a-select
          mode="multiple"
          placeholder="请选择工作组"
          :default-value="chooseList"
          style="width: 200px"
          v-model="formData.groupIdList"
      >
        <a-select-option v-for="(item,index) in projectList" :key="index" :value="item.groupId">
          {{item.groupName}}
        </a-select-option>
      </a-select>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: "userAdd",
  data() {
    return {
      confirmLoading: false,
      formData:{},
      options: [],
      role_list:[],
      chooseList:[],
      projectList:[],
      groupIdList: sessionStorage.getItem('groupId')
    };
  },
  props:['disabled'],
  mounted() {
    this.get_role()
    this.get_project()
  },
  methods: {
    handleOk() {
      this.$axios.post(this.api+'/user/add',this.formData).then(
          res=>{
            if(res.code === 200){
              this.$emit('showModal',false)
              this.$message.success('添加成功')
              this.formData = {}
            }
          }
      )
    },
    handleCancel(e) {
      this.$emit('showModal',false)
      this.formData = {}
    },
    //查询角色
    get_role() {
      let that = this;
      this.$axios.get(this.api+'/role/showall',{
        params:{
          username:window.localStorage.getItem('username')
        }
      }).then(res => {
        if (res.code === 200) {
          that.role_list = res.data.list;
        }
      })
    },
    //查询项目
    get_project(){
      this.$axios.get(this.api+'/group/list').then(res=>{
        if(res.code === 200){
          this.projectList = res.data.list;
        }
      })
    },
  },
}
</script>

<style scoped>
.input{
  width: 200px;
}
.oneDiv{
  display: flex;
  font-size: 14px;
  margin-bottom: 10px;
}
.oneDiv >p{
  width: 100px;
}
</style>
