<template>
  <a-modal
      title="修改用户"
      :visible="disabledChange"
      :confirm-loading="confirmLoading"
      :maskClosable="false"
      okText="提交"
      cancelText="取消"
      @ok="handleOk"
      @cancel="handleCancel"
  >
    <div class="oneDiv">
      <p>用户名：</p>
      <a-input class="input" placeholder="请输入用户名" v-model="oneData.username"/>
    </div>
    <div class="oneDiv">
      <p>登录密码：</p>
      <a-input-password class="input" placeholder="请输入登录密码" v-model="oneData.password"/>
    </div>
    <!-- <div class="oneDiv">
      <p>用户姓名：</p>
      <a-input class="input" placeholder="请输入用户姓名" v-model="oneData.nickname"/>
    </div> -->
    <div class="oneDiv">
      <p>角色：</p>
      <a-select v-model="oneData.roleId" style="width: 200px;" mode="default" placeholder="请选择角色">
        <a-select-option v-for="(a, index) in role_list" :key="index" :value="a.roleId">
          {{ a.name }}
        </a-select-option>
      </a-select>
    </div>
    <div class="oneDiv">
      <p>工作组：</p>
      <a-select
          mode="multiple"
          placeholder="请选择工作组"
          v-model="oneData.groupIdList"
          style="width: 200px"
      >
        <a-select-option v-for="(item,index) in projectList" :key="index" :value="item.groupId">
          {{item.groupName}}
        </a-select-option>
      </a-select>
    </div>
    <div class="oneDiv">
      <p>除治详情修改：</p>
      <a-switch v-model="oneData.modify" checked-children="开" un-checked-children="关" default-checked />
    </div>
    <div class="oneDiv">
      <p>除治修改：</p>
      <a-switch v-model="oneData.hyModifyEli" checked-children="开" un-checked-children="关" default-checked />
    </div>
    <div class="oneDiv">
      <p>普查修改：</p>
      <a-switch v-model="oneData.modifyInv" checked-children="开" un-checked-children="关" default-checked />
    </div>
    <div class="oneDiv">
      <p>除治数据导入：</p>
      <a-switch v-model="oneData.eliminationImport" checked-children="开" un-checked-children="关" default-checked />
    </div>
    <div class="oneDiv">
      <p>账号权限：</p>
      <a-switch v-model="oneData.enabled" checked-children="开" un-checked-children="关" default-checked />
    </div>
  </a-modal>
</template>

<script>
export default {
  name: "userChange",
  data() {
    return {
      confirmLoading: false,
      options: [],
      role_list:[],
      chooseList:[],
      projectList:[],
      groupIdList: sessionStorage.getItem('groupId'),
    };
  },
  props:['disabledChange','oneData','password'],
  mounted() {
    this.get_role()
    this.get_project()
  },
  methods: {
    handleOk() {
      let data = {}
      if(this.oneData.enabled) {
        this.oneData.enabled = 1
      } else {
        this.oneData.enabled = 0
      }
      if(this.oneData.modify) {
        this.oneData.modify = '1'
      } else {
        this.oneData.modify = '0'
      }
      if(this.oneData.hyModifyEli) {
        this.oneData.hyModifyEli = '1'
      } else {
        this.oneData.hyModifyEli = '0'
      }
      if(this.oneData.modifyInv) {
        this.oneData.modifyInv = '1'
      } else {
        this.oneData.modifyInv = '0'
      }
      if(this.oneData.eliminationImport) {
        this.oneData.eliminationImport = '1'
      } else {
        this.oneData.eliminationImport = '0'
      }
      if(this.oneData.password !== this.password) {
        data.userId = this.oneData.userId
        data.username = this.oneData.username
        data.roleId = this.oneData.roleId
        data.password = this.oneData.password
        data.groupIdList = this.oneData.groupIdList
        data.modify = this.oneData.modify
        data.hyModifyEli = this.oneData.hyModifyEli
        data.modifyInv = this.oneData.modifyInv
        data.eliminationImport = this.oneData.eliminationImport
        data.enabled = this.oneData.enabled
      } else if(this.oneData.password === this.password) {
        data.userId = this.oneData.userId
        data.username = this.oneData.username
        data.roleId = this.oneData.roleId
        data.groupIdList = this.oneData.groupIdList
        data.modify = this.oneData.modify
        data.hyModifyEli = this.oneData.hyModifyEli
        data.modifyInv = this.oneData.modifyInv
        data.eliminationImport = this.oneData.eliminationImport
        data.enabled = this.oneData.enabled
      }
      this.$axios.put('/plague/api/user/update',data).then(
          res=>{
            console.log(res);
            if(res.code === 200) {
              this.$emit('getChangeState',false)
              this.$message.success('修改成功,请手动刷新')
            }
          }
      )
    },
    handleCancel(e) {
      this.$emit('getChangeState',false)
      // this.$message.success('请手动刷新')
    },
    //查询角色
    get_role() {
      let that = this;
      this.$axios.get(this.api+'/role/showall',{
        params:{
          username:window.localStorage.getItem('username')
        }
      }).then(res => {
        if (res.code === 200) {
          that.role_list = res.data.list;
          // this.$message.success('查询成功！！！')
        }
      })
    },
    //查询工作组
    get_project(){
      this.$axios.get(this.api+'/group/list').then(res=>{
        if(res.code === 200){
          this.projectList = res.data.list;
        }
      })
    },
  },
}
</script>

<style scoped>
.input {
  width: 200px;
}
.oneDiv {
  display: flex;
  font-size: 14px;
  margin-bottom: 10px;
}
.oneDiv >p {
  width: 100px;
}
</style>
