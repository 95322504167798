<template>
  <div class="home">
    <div class="header">
      <!-- <a-input class="input" placeholder="请输入用户名称"/>
      <a-button type="primary" icon="search">查询</a-button> -->
      <a-divider type="vertical" />
       <span style="color: #000000;">用户名：</span>
        <a-input
          v-model="username"
          style="
            width: 15%;
            margin-right: 40px;
            background-color: transparent;
            color: #000000;
          "
          placeholder="请输入上传用户"
        ></a-input>
      <span style="color: #000000;">工作组：</span>
      <a-select
          allowClear
          v-model="groupId"
          style="
            width: 180px;
            margin-right: 40px;
            background-color: transparent;
          "
        >
          <a-select-option
            :value="item.groupId"
            v-for="(item, index) in groupList"
            :key="index"
          >
            {{ item.groupName }}
          </a-select-option>
        </a-select>
        <a-button type="primary" @click="getProjectList" style="margin-right:18px;">查询</a-button>
        <a-button @click="reset" style="margin-right:18px;">重置</a-button>
        <a-button type="primary" style="margin-right:18px;" icon="plus" @click="showModal">新增用户</a-button>
        <a-button type="primary" @click="controlClick">批量控制</a-button>
    </div>
    <userAdd
        :disabled="disabled"
        @showModal="getData"
    >
    </userAdd>
    <userChange :disabledChange="disabledChange" :oneData="oneData" :password="password" @getChangeState="getChangeState"></userChange>

    <div class="main">
      <a-table
          :columns="columns"
          :data-source="data"
          bordered
          :rowKey="data => data.userId"
      >
        <template slot="enabled"  slot-scope="text, record, index">
          {{text ? '正常' : '停用'}}
        </template>
        <template slot="create_time" slot-scope="text">
          {{ text | dateTime }}
        </template>
        <template slot="last_time" slot-scope="text">
          {{ text | dateTime }}
        </template>
        <span slot="action" slot-scope="text, record">
            <a-button type="primary" @click="showChangeModal(record)">修改</a-button>
        </span>
      </a-table>
    </div>
    <!-- 权限配置 -->
    <el-dialog title="权限配置" :visible.sync="opens" width="626px" style="margin-top: 6vh;" append-to-body>
      <div>
        <el-transfer
          filterable
          :titles="['正常', '停用']"
          :filter-method="filterMethod"
          filter-placeholder="请输入搜索内容"
          v-model="userIdList"
          :data="datas"
          @change="filterChange">
        </el-transfer>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForms">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import userAdd from "./modal/userAdd";
import userChange from "./modal/userChange";
export default {
  name: "use",
  data() {
    return {
      disabled:false,
      disabledChange:false,
      oneData:{},
      password: '',
      data:[],
      groupList: [], //作业人员列表/工作组
      groupIdList: sessionStorage.getItem("groupId"), // 工作组id
      groupId: '',
      username: '',
      columns:[
        {
          title: '用户名',
          dataIndex: 'username',
        },
        {
          title: '工作组',
          dataIndex: 'nickName',
        },
        {
          title: '角色',
          dataIndex: 'roleName',
        },
        {
          title: '账号状态',
          dataIndex: 'enabled',
          scopedSlots: { customRender: 'enabled' },
        },
        // {
        //   title: '项目',
        //   dataIndex: 'leader',
        // },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
        },
        {
          title: '最后登录时间',
          dataIndex: 'updateTime',
          scopedSlots: { customRender: 'updateTime' },
        },
        {
          title: '操作',
          scopedSlots: {customRender: 'action'},
          key: 'action',
          width: 400
        },
      ],
      opens: false,
      datas: null,
      userIdList: [],
      filterMethod(query, item) {
        return item.label.indexOf(query) > -1;
      },
    }
  },
  components:{
    userAdd,
    userChange
  },
  mounted() {
    this.getProjectList()
    this.handleGroup();
  },
  methods:{
    showModal(){
      this.disabled = true
    },
    // 批量控制
    controlClick() {
      this.userIdList = []
      this.$axios(this.api + "/user/select").then(
        (res) => {
          if (res.code === 200) {
            const generateData = _ => {
             const datas = [];
             const cities = res.data;
             cities.forEach((item, index) => {
                datas.push({
                  label: item.username,
                  key: item.userId,
                  sort: index+1
                });
                if(!item.enabled) {
                  this.userIdList.push(item.userId)
                }
              });
              return datas;
            };
            this.datas = generateData()
            this.opens = true;
          }
        }
      );
    },
    filterChange(value, direction, movedKeys) {
      this.userIdList = value
      this.$forceUpdate();
    },
    async submitForms() {
      if(this.userIdList === undefined || this.userIdList.length === 0) {
         return this.$message.info('未选择数据，请选择后提交')
       }
       let data = {
          userIdList: this.userIdList,
          status: 0
       }
       const res = await this.$axios.put(this.api + "/user/batch/enabled", data);
       if (res.code === 200) {
        this.$message.success("提交成功，请手动更新");
        this.opens = false
      } else {
        this.$message.info("提交失败，请重试");
      }
    },
    cancel() {
      this.opens = false;
      this.userIdList = []
      this.datas = null
    },
    // 获取工作组/作业人员
    handleGroup() {
      this.$axios(
        this.api + `/group/list?groupIdList=${this.groupIdList}`
      ).then((res) => {
        this.groupList = res.data.list;
      });
    },
    reset() {
      this.groupId = ''
      this.username = ''
      this.getProjectList()
    },
    getData(data){
      this.disabled = data
      this.getProjectList()
    },
    getProjectList(){
      this.$axios.get(this.api+`/user/showall?groupId=${this.groupId}&username=${this.username}`).then(
          res=>{
            if(res.code===200){
              this.data = res.data.list
            }
          }
      )
    },
    //修改
    showChangeModal(record) {
      if(record.modify === '1') {
        record.modify = true
      } else {
        record.modify = false
      }
      if(record.hyModifyEli === '1') {
        record.hyModifyEli = true
      } else {
        record.hyModifyEli = false
      }
      if(record.modifyInv === '1') {
        record.modifyInv = true
      } else {
        record.modifyInv = false
      }
      if(record.eliminationImport === '1') {
        record.eliminationImport = true
      } else {
        record.eliminationImport = false
      }
      this.disabledChange = true
      this.oneData = record
      this.password = record.password
    },
    getChangeState(){
      this.disabledChange = false
    }
  }
}
</script>

<style scoped>
.home{
  height: 100%;
  padding: 15px;
}
.header{
  width: 100%;
  background: #fff;
  height: 60px;
  text-align: left;
  padding: 15px;
}
.main{
  height: calc(100% - 80px);
  background: #fff;
  margin-top: 20px;
  border-radius: 15px;
  padding: 10px;
}
.input{
  width: 180px;
  margin-right: 20px;
}
div /deep/ .el-dialog {
  margin-top: 1vh !important;
}
div /deep/ .el-transfer-panel__body {
  height: 61vh;
}
div /deep/ .el-transfer-panel__list.is-filterable {
  height: 56vh;
}
</style>
